import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import ProductGrid from "./components/ProductGrid";
import Home from "./components/Home";

function App() {
	return (
		<div className="min-h-screen bg-[#10151D]">
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/products/:category" element={<ProductGrid />} />
			</Routes>
		</div>
	);
}

export default App;
