import HeroSlider from "./HeroSlider";
import NewCollections from "./NewCollections";
import FeaturedSection from "./FeaturedSection";
import ProductCategories from "./ProductCategories";
import AboutSection from "./AboutSection";
import Footer from "./Footer";

function Home() {
    return (
        <div className="min-h-screen bg-gray-100">
            <HeroSlider />
            <NewCollections />
            <FeaturedSection />
            <section id="products" className="bg-[#A8AFB7] bg-gray-200 py-12 px-6 md:px-12">
                <div className="container mx-auto">
                    <h2 className="text-4xl font-chapman-bold text-center mb-8">Our Products</h2>
                    <ProductCategories />
                </div>
            </section>
            <AboutSection />
            <Footer />
        </div>
    );
}

export default Home;
