import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_SERVER_URL } from "../../config/Config";

const Admin = () => {
    const navigate = useNavigate();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [category, setCategory] = useState("heroslider");
    const [images, setImages] = useState([]);
    const [uploading, setUploading] = useState(false);

    // For handling text inputs for each selected file
    const [fileTexts, setFileTexts] = useState({});

    // Product categories that require text input
    const textRequiredCategories = [
        "new_arrivals", "rings", "necklaces", "bracelets", "earrings",
        "bangles", "pendants", "anklets", "chains", "nose_pins",
        "coins_bars", "silver_coins"
    ];

    // Check if current category requires text
    const isTextRequired = textRequiredCategories.includes(category);
    const [editedTexts, setEditedTexts] = useState({});

    useEffect(() => {
        fetchImages();
    }, [category]);

    const fetchImages = async () => {
        try {
            const response = await fetch(`${ADMIN_SERVER_URL}/images?category=${category}`);
            if (!response.ok) throw new Error("Failed to fetch images");
            const data = await response.json();

            // Log the data to debug
            console.log("Fetched images data:", data);

            setImages(Array.isArray(data) ? data : []);
            setEditedTexts({});
        } catch (error) {
            console.error("Error fetching images:", error);
            setImages([]);
        }
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);

        // Initialize text fields for new files
        if (isTextRequired) {
            const newFileTexts = { ...fileTexts };
            files.forEach((file) => {
                // Use file name as key
                if (!newFileTexts[file.name]) {
                    newFileTexts[file.name] = "";
                }
            });
            setFileTexts(newFileTexts);
        }
    };

    const handleTextChange = (fileName, text) => {
        setFileTexts({
            ...fileTexts,
            [fileName]: text
        });
    };

    const handleUpload = async () => {
        if (selectedFiles.length === 0) return alert("Please select at least one file!");

        // Check if text is required but missing
        if (isTextRequired) {
            const missingText = selectedFiles.some(file => !fileTexts[file.name]);
            if (missingText) {
                return alert("Please add text for all images in this category!");
            }
        }

        const formData = new FormData();
        selectedFiles.forEach((file) => {
            formData.append("files", file);
        });

        // Add text for each file if applicable
        if (isTextRequired) {
            formData.append("fileTexts", JSON.stringify(fileTexts));
        }

        formData.append("category", category);

        try {
            setUploading(true);
            const response = await fetch(`${ADMIN_SERVER_URL}/upload`, {
                method: "POST",
                body: formData,
            });

            const data = await response.json();
            setUploading(false);

            if (response.ok) {
                alert("Upload successful!");
                fetchImages();
                setSelectedFiles([]);
                setFileTexts({});
            } else {
                alert("Upload failed: " + data.error);
            }
        } catch (error) {
            setUploading(false);
            alert("Error uploading files.");
            console.error("Upload error:", error);
        }
    };

    const handleDelete = async (public_id) => {
        if (!window.confirm("Are you sure you want to delete this image?")) return;

        try {
            const response = await fetch(`${ADMIN_SERVER_URL}/delete`, {
                method: "DELETE",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ public_id }),
            });

            if (response.ok) {
                alert("Image deleted!");
                fetchImages();
            } else {
                alert("Failed to delete image.");
            }
        } catch (error) {
            console.error("Delete failed:", error);
        }
    };

    const handleUpdateText = async (public_id, newText) => {
        try {
            const response = await fetch(`${ADMIN_SERVER_URL}/update-text`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    public_id,
                    text: newText
                }),
            });

            if (response.ok) {
                alert("Text updated successfully!");
                fetchImages();
                setEditedTexts(prev => ({ ...prev, [public_id]: false }));
            } else {
                const errorData = await response.json();
                alert("Failed to update text: " + (errorData.error || "Unknown error"));
            }
        } catch (error) {
            console.error("Update text failed:", error);
            alert("Error updating text: " + error.message);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const handleInputChange = (public_id, value, originalText) => {
        setImages(prevImages => prevImages.map(img =>
            img.public_id === public_id ? { ...img, text: value } : img
        ));
        setEditedTexts(prev => ({ ...prev, [public_id]: value !== originalText }));
    };

    return (
        <div className="p-6 text-white min-h-screen bg-gray-900">
            <div className="flex items-center justify-between m-4">
                <h1 className="text-3xl font-bold">Admin Panel</h1>

                <button onClick={handleLogout} className="w-32 bg-red-500 hover:bg-red-600 py-2 rounded">
                    Logout
                </button>
            </div>

            <div className="bg-gray-800 p-4 rounded-lg">
                <label className="block text-gray-300">Select Category:</label>
                <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full p-2 rounded bg-gray-700 text-white border-none focus:outline-none"
                >
                    <option value="heroslider">Hero Slider</option>
                    <option value="newcollections/big">New Collections (Big)</option>
                    <option value="newcollections/medium">New Collections (Medium)</option>
                    <option value="newcollections/small1">New Collections (Small Section 1)</option>
                    <option value="newcollections/small2">New Collections (Small Section 2)</option>
                    <option value="featured">Featured Section</option>
                    <option value="new_arrivals">New Arrivals</option>
                    <option value="rings">Rings</option>
                    <option value="necklaces">Necklaces</option>
                    <option value="bracelets">Bracelets</option>
                    <option value="earrings">Earrings</option>
                    <option value="bangles">Bangles</option>
                    <option value="pendants">Pendants</option>
                    <option value="anklets">Anklets</option>
                    <option value="chains">Chains</option>
                    <option value="nose_pins">Nose Pins</option>
                    <option value="coins_bars">Coins & Bars</option>
                    <option value="silver_coins">Silver Coins</option>
                </select>

                <input
                    type="file"
                    multiple accept="image/*"
                    onChange={handleFileChange}
                    className="mt-2 w-full p-2 bg-gray-700 text-white rounded focus:outline-none"
                />
                <button
                    onClick={handleUpload}
                    className="mt-2 w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded"
                    disabled={uploading}
                >
                    {uploading ? "Uploading..." : "Upload"}
                </button>
            </div>

            {selectedFiles.length > 0 && (
                <div className="mt-4">
                    <h2 className="text-lg font-bold">Selected Files:</h2>
                    <div className="grid grid-cols-3 gap-4 mt-2">
                        {selectedFiles.map((file, index) => (
                            <div key={index} className="relative bg-gray-800 p-4 rounded-lg">
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt="Preview"
                                    className="w-full h-64 object-cover rounded-lg"
                                />
                                <button
                                    onClick={() => {
                                        const newSelectedFiles = selectedFiles.filter((_, i) => i !== index);
                                        setSelectedFiles(newSelectedFiles);

                                        // Also remove from fileTexts if present
                                        const newFileTexts = { ...fileTexts };
                                        delete newFileTexts[file.name];
                                        setFileTexts(newFileTexts);
                                    }}
                                    className="absolute top-6 right-6 bg-red-500 text-white px-2 rounded"
                                >X</button>

                                {isTextRequired && (
                                    <div className="mt-2">
                                        <label className="block text-gray-300 mb-1">Product Description:</label>
                                        <textarea
                                            value={fileTexts[file.name] || ""}
                                            onChange={(e) => handleTextChange(file.name, e.target.value)}
                                            className="w-full p-2 bg-gray-700 text-white rounded resize-none focus:outline-none"
                                            placeholder="Enter product description."
                                            rows="3"
                                            maxLength={250}
                                        />
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="mt-8">
                {images.length > 0 && (
                    <div className="mb-4">
                        <h2 className="text-lg font-bold">Already Present Files in {category}:</h2>
                        {isTextRequired && <p className="text-gray-400 text-sm mt-1">You can edit the text for these images.</p>}
                    </div>
                )}
                <div className="grid grid-cols-3 gap-6">
                    {images.length > 0 ? (
                        images.map((img) => (
                            <div key={img.public_id} className="relative bg-gray-800 p-4 rounded-lg">
                                <img src={img.url} alt="Uploaded" className="w-full h-64 object-cover rounded-lg" />
                                <button
                                    onClick={() => handleDelete(img.public_id)}
                                    className="absolute top-6 right-6 bg-red-500 text-white px-2 rounded"
                                >X</button>

                                {isTextRequired && (
                                    <div className="mt-3">
                                        <label className="block text-gray-300 mb-1">Product Description:</label>
                                        <div className="flex">
                                            <textarea
                                                defaultValue={img.text || ""}
                                                onChange={(e) => handleInputChange(img.public_id, e.target.value, img.text)}
                                                className="flex-grow p-2 bg-gray-700 text-white rounded-l resize-none focus:outline-none"
                                                placeholder="Enter product description."
                                                rows="3"
                                                maxLength={250}
                                                id={`text-${img.public_id}`}
                                            />
                                            <button
                                                onClick={() => handleUpdateText(img.public_id, img.text)}
                                                className={`bg-green-600 hover:bg-green-700 text-white px-3 rounded-r ${editedTexts[img.public_id] ? '' : 'opacity-50 cursor-not-allowed'}`}
                                                disabled={!editedTexts[img.public_id]}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <p className="col-span-2 text-red-400">No images found in {category}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Admin;
