import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ADMIN_SERVER_URL } from "../../config/Config";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = async () => {
        const response = await fetch(`${ADMIN_SERVER_URL}/login`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username, password }),
        });

        const data = await response.json();
        if (response.ok) {
            localStorage.setItem("token", data.token);
            navigate("/admin");
        } else {
            alert("Invalid credentials!");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-[#10151D] text-black">
            <h1 className="text-2xl mb-4 text-white">Admin Login</h1>
            <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mb-2 px-4 py-2"
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mb-2 px-4 py-2"
            />
            <button onClick={handleLogin} className="bg-blue-500 px-4 py-2 text-white">
                Login
            </button>
        </div>
    );
};

export default Login;
