import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from "react-router-dom";
import Config, { ADMIN_SERVER_URL } from '../config/Config';

const getCategoryById = (categoryId) => {
	if (!categoryId) return null;
	const category = Config.ProductCategories.find(cat => cat.id === categoryId);
	return category ? { ...category } : null;
}

const ProductGrid = () => {
	const { category } = useParams();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [categoryData, setCategoryData] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		// Reset states when category changes
		setIsLoading(true);
		setError(null);

		const fetchData = async () => {
			try {
				// Try to fetch from API first
				let data = null;
				try {
					const response = await fetch(`${ADMIN_SERVER_URL}/images?category=${category}`);
					if (response.ok) {
						const apiData = await response.json();
						if (Array.isArray(apiData) && apiData.length > 0) {
							data = apiData;
						}
					}
				} catch (apiError) {
					console.error("API fetch error:", apiError);
					// Continue to fallback if API fails
				}

				// Use fallback data if API fetch failed or returned invalid data
				if (!data) {
					const fallbackData = getCategoryById(category);

					if (!fallbackData) {
						setError("Category not found");
						return;
					}

					if (!fallbackData.data || !Array.isArray(fallbackData.data) || fallbackData.data.length === 0) {
						setError("No products found for this category");
						return;
					}

					data = fallbackData.data;
				}

				setCategoryData(data);
			} catch (err) {
				console.error("Error loading category data:", err);
				setError("Failed to load products");
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();

		// Scroll to the top of the page
		window.scrollTo(0, 0);
	}, [category]);

	const itemVariants = {
		hidden: {
			opacity: 0,
			scale: 0.8,
			y: 50
		},
		visible: {
			opacity: 1,
			scale: 1,
			y: 0,
			transition: {
				duration: 0.8,
				ease: [0.42, 0, 0.58, 1]
			}
		}
	};

	// Show loading state
	if (isLoading) {
		return (
			<div className="flex justify-center items-center min-h-[300px]">
				<div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
			</div>
		);
	}

	// Show error state with option to go back
	if (error) {
		return (
			<div className="flex flex-col justify-center items-center min-h-[300px] p-6 text-center">
				<h2 className="text-2xl font-chapman-bold text-red-600 mb-4">{error}</h2>
				<p className="mb-6">Sorry, we couldn't find what you're looking for.</p>
				<button
					onClick={() => navigate('/')}
					className="px-6 py-2 bg-amber-600 text-white rounded-md hover:bg-amber-700 transition-colors"
				>
					Return to Home
				</button>
			</div>
		);
	}

	return (
		<section id="products" className="bg-[#A8AFB7] bg-gray-200 py-12 px-6 md:px-12">
			<div className="container mx-auto">
				<h2 className="text-4xl font-chapman-bold text-center mb-8">{categoryData.name}</h2>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{categoryData.map((element, i) => (
						<motion.div
							key={i}
							className="relative group overflow-hidden bg-white p-2 shadow"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true, amount: 0.2 }}
							variants={itemVariants}
						>
							<img
								src={element.url}
								alt={`Product ${i + 1}`}
								className="w-full h-96 object-cover transition-all duration-500 group-hover:scale-110"
							/>
							<div
								className="absolute bottom-0 left-0 w-full h-1/2 bg-black bg-opacity-70 text-white flex justify-center transform translate-y-full group-hover:translate-y-1/4 transition-transform duration-500 ease-in-out p-6"
							>
								<p className="font-chapman text-center text-sm">{element.text}</p>
							</div>
						</motion.div>
					))}
				</div>
			</div>
		</section>
	);
};

export default ProductGrid;