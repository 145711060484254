import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../config/Config";

const Navbar = () => {
	const navigate = useNavigate();

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeSection, setActiveSection] = useState("home");
	const [isVisible, setIsVisible] = useState(false);

	// References for the mobile menu and menu button
	const mobileMenuRef = useRef(null);
	const menuButtonRef = useRef(null);

	// Trigger animation on mount
	useEffect(() => {
		setTimeout(() => {
			setIsVisible(true);
		}, 100);
	}, []);

	// Smooth scroll to section
	const handleMenuClick = (e, sectionId) => {
		e.preventDefault();
		navigate("/", { replace: false }); // Navigate to the Home route
		setTimeout(() => {
			const section = document.getElementById(sectionId);
			if (section) {
				const headerHeight = document.querySelector('header').offsetHeight; // Get the sticky header height
				const sectionPosition = section.offsetTop - headerHeight; // Calculate the adjusted position
				window.scrollTo({
					top: sectionPosition,
					behavior: "smooth",
				});
			}
		}, 100); // Delay ensures the DOM is ready
		setActiveSection(sectionId);
		setIsMenuOpen(false);
	};

	// Smooth scroll to home
	const handleHomeClick = (e) => {
		e.preventDefault();
		// Redirect to the home route
		navigate("/");

		// Smooth scroll to the top
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}, 100); // Small delay ensures routing is complete
		setActiveSection("home");
		setIsMenuOpen(false);
	};

	// Intersection Observer for active section
	useEffect(() => {
		const sections = document.querySelectorAll("section");
		const observerOptions = {
			root: null,
			threshold: 0.2, // Adjust for when a section is 20% visible
		};

		const observerCallback = (entries) => {
			const visibleSections = entries.filter((entry) => entry.isIntersecting);
			if (visibleSections.length > 0) {
				const closestSection = visibleSections.reduce((prev, curr) =>
					prev.boundingClientRect.top < curr.boundingClientRect.top ? prev : curr
				);
				setActiveSection(closestSection.target.id);
			}
		};

		const observer = new IntersectionObserver(observerCallback, observerOptions);

		sections.forEach((section) => observer.observe(section));

		// Close menu on outside click
		const handleOutsideClick = (e) => {
			if (
				mobileMenuRef.current &&
				!mobileMenuRef.current.contains(e.target) &&
				!menuButtonRef.current.contains(e.target)
			) {
				setIsMenuOpen(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			sections.forEach((section) => observer.unobserve(section));
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	// Scroll to top detection
	useEffect(() => {
		const scrollHandler = () => {
			if (window.scrollY === 0) {
				setActiveSection("home");
			}
		};

		window.addEventListener("scroll", scrollHandler);

		return () => {
			window.removeEventListener("scroll", scrollHandler);
		};
	}, []);

	return (
		<>
			<style>
				{`
                    .nav-slide-down {
                        opacity: 0;
                        transform: translateY(-100%);
                        transition: transform 0.6s ease, opacity 0.6s ease;
                    }

                    .nav-slide-down.visible {
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .nav-item {
                        opacity: 0;
                        transform: translateY(20px);
                        transition: all 0.6s ease;
                    }

                    .visible .nav-item {
                        opacity: 1;
                        transform: translateY(0);
                    }

                    .logo-slide-in {
                        opacity: 0;
                        transform: translateX(-50px);
                        transition: all 0.6s ease;
                    }

                    .visible .logo-slide-in {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    .contact-slide-in {
                        opacity: 0;
                        transform: translateX(50px);
                        transition: all 0.6s ease;
                    }

                    .visible .contact-slide-in {
                        opacity: 1;
                        transform: translateX(0);
                    }

                    .visible .nav-item:nth-child(1) { transition-delay: 0.2s; }
                    .visible .nav-item:nth-child(2) { transition-delay: 0.35s; }
                    .visible .nav-item:nth-child(3) { transition-delay: 0.5s; }
                    .visible .nav-item:nth-child(4) { transition-delay: 0.65s; }
                    .visible .nav-item:nth-child(5) { transition-delay: 0.8s; }

                    .visible .logo-slide-in { transition-delay: 0.4s; }
                    .visible .contact-slide-in { transition-delay: 0.5s; }
                `}
			</style>

			<header className={`nav-slide-down ${isVisible ? 'visible' : ''} bg-[#10151D] text-white sticky top-0 z-50`}>
				<div className="container mx-auto flex flex-col lg:flex-row items-center justify-end pt-4 pb-1 px-8">
					<div className="md:ml-auto font-chapman contact-slide-in">
						<div className="flex text-sm lg:text-md flex-row space-x-8">
							<a href="tel:920003951" className="flex flex-row items-center">
								<i className="fa-solid fa-headset mr-2 text-lg"></i>
								<span>920003951</span>
							</a>
							<a href="https://wa.me/966537561652" className="flex flex-row items-center">
								<i className="fa-brands fa-whatsapp mr-2 text-lg"></i>
								<span>+966537561652</span>
							</a>
						</div>
					</div>
				</div>

				<div className="container mx-auto flex items-center justify-between px-8 pb-2">
					<img className="h-14 lg:block hidden logo-slide-in" src={Config.Navbar.logoimg} alt="Logo" onClick={() => { window.location.href = "/" }} />

					<nav className="w-full lg:w-auto">
						<div className="hidden lg:flex items-center justify-end space-x-8 py-4">
							{[
								{ id: "home", text: "Home", handler: handleHomeClick },
								{ id: "new_collections", text: "New collections", handler: handleMenuClick },
								{ id: "feature", text: "Feature", handler: handleMenuClick },
								{ id: "products", text: "Our Products", handler: handleMenuClick },
								{ id: "about", text: "About Us", handler: handleMenuClick },
							].map((item) => (
								<p key={item.id} className="group cursor-pointer relative w-max nav-item">
									<a
										href={`#${item.id}`}
										onClick={(e) => item.handler(e, item.id)}
										className={`font-chapman-medium group-hover:text-white text-xl ${activeSection === item.id ? "text-white" : "text-[#A8AFB7]"}`}
									>
										{item.text}
									</a>
									<span className="absolute -bottom-1 left-0 w-0 transition-all duration-300 h-[0.15rem] bg-[#ddb02a] group-hover:w-full"></span>
								</p>
							))}
						</div>

						<div className="lg:hidden flex justify-between items-center px-4 py-3">
							<img className="h-10 logo-slide-in" src={Config.Navbar.logoimg} alt="Logo" onClick={() => { window.location.href = "/" }}/>
							<button
								ref={menuButtonRef}
								onClick={() => setIsMenuOpen(!isMenuOpen)}
								className="text-white"
							>
								<svg
									className="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M4 6h16M4 12h16M4 18h16"
									/>
								</svg>
							</button>
						</div>

						<div
							ref={mobileMenuRef}
							className={`overflow-hidden transition-all duration-300 ease-in-out ${isMenuOpen ? "max-h-60 py-4" : "max-h-0"}`}
						>
							<div className="lg:hidden text-center py-2">
								{[
									{ id: "home", text: "Home", handler: handleHomeClick },
									{ id: "new_collections", text: "New collections", handler: handleMenuClick },
									{ id: "feature", text: "Feature", handler: handleMenuClick },
									{ id: "products", text: "Our Products", handler: handleMenuClick },
									{ id: "about", text: "About Us", handler: handleMenuClick },
								].map((item) => (
									<p key={item.id} className="py-1 text-lg">
										<a
											href={`#${item.id}`}
											onClick={(e) => item.handler(e, item.id)}
											className={`font-chapman-medium ${activeSection === item.id ? "text-white" : "text-[#A8AFB7]"}`}
										>
											{item.text}
										</a>
									</p>
								))}
							</div>
						</div>
					</nav>
				</div>
			</header>
		</>
	);
};

export default Navbar;
