import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const MediumSlider = ({ images, inView = true }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [direction, setDirection] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDirection(1);  // Always moving forward
            setCurrentSlide((prev) => (prev + 1) % images.length); // Loop back to 0 after last image
        }, 4000);
        return () => clearInterval(interval);  // Cleanup interval on component unmount
    }, [images]);

    // Slide transition variants: Horizontal sliding effect with full coverage
    const slideVariants = {
        initial: (direction) => ({
            x: direction > 0 ? '100%' : '-100%',  // Slide in from right or left
            opacity: 0,
        }),
        animate: {
            x: 0,
            opacity: 1,
            transition: {
                duration: 0.7,
                ease: [0.42, 0, 0.58, 1],
            },
        },
        exit: (direction) => ({
            x: direction > 0 ? '-100%' : '100%',  // Slide out to the left or right
            opacity: 0,
            transition: {
                duration: 0.7,
                ease: [0.42, 0, 0.58, 1],
            },
        }),
    };

    const containerVariants = {
        hidden: {
            opacity: 0,
            y: 50,
            scale: 0.9
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 1.2,
                ease: [0.215, 0.61, 0.355, 1]
            }
        }
    };

    const handleSlideChange = (index) => {
        setDirection(index > currentSlide ? 1 : -1);
        setCurrentSlide(index);
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className="relative h-[20vh] lg:h-[300px] overflow-hidden rounded-sm shadow-md"
        >
            <AnimatePresence initial={false} custom={direction}>
                <motion.img
                    key={currentSlide}
                    src={images[currentSlide].url}
                    alt={`Medium Slider ${currentSlide + 1}`}
                    custom={direction}
                    variants={slideVariants}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    className="absolute w-full h-full object-cover" // Ensuring image fills the full container
                />
            </AnimatePresence>

            <motion.div
                className="absolute bottom-2 inset-x-0 flex justify-center space-x-2 z-20"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 0.3, type: "spring" }}
            >
                {images.map((_, index) => (
                    <motion.span
                        key={index}
                        className={`w-2 h-2 rounded-full cursor-pointer ${index === currentSlide ? "bg-white" : "bg-gray-400"
                            }`}
                        onClick={() => handleSlideChange(index)}
                        whileHover={{ scale: 1.3 }}
                        whileTap={{ scale: 0.8 }}
                    />
                ))}
            </motion.div>
        </motion.div>
    );
};

export default MediumSlider;
