import { motion } from "framer-motion";
import CONFIG, { ADMIN_SERVER_URL } from "../config/Config";
import { useEffect } from "react";

const FeaturedSection = () => {
	let image = CONFIG.FeaturedSection.image;

	// Fetch images from API
	useEffect(() => {
		const fetchImage = async () => {
			try {
				const response = await fetch(`${ADMIN_SERVER_URL}/images?category=featured`); // Update API URL
				if (!response.ok) throw new Error("Failed to fetch slides");
				const data = await response.json();

				// Check if API returns valid data
				if (Array.isArray(data) && data.length > 0) {
					image = data[0];
				} else if (data) {
					image = data;
				}
			} catch (error) {
				console.error("Error fetching hero slides:", error);
				image = CONFIG.FeaturedSection.image; // Fallback to config
			}
		};

		fetchImage();
	}, []);

	// Animation Variants
	const titleAnination = {
		hidden: {
			opacity: 0,
			scale: 1.1,
			filter: "blur(10px)"
		},
		visible: {
			opacity: 1,
			scale: 1,
			filter: "blur(0px)",
			transition: {
				duration: 1,
				ease: [0.6, 0.01, 0.32, 1]
			}
		}
	}

	const itemVariants = {
		hidden: { opacity: 0, y: 50 },
		visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: "easeOut" } },
	};

	const imageVariants = {
		hidden: { opacity: 0, scale: 0.8, rotate: -10 },
		visible: {
			opacity: 1,
			scale: 1,
			rotate: 0,
			transition: { duration: 1, ease: "easeOut" },
		},
	};

	return (
		<section id="feature" className="container mx-auto py-12 px-6 md:px-12">
			<div
				className="flex flex-col md:flex-row gap-8"
			>
				{/* Image Animation */}
				<motion.div
					className="w-full lg:w-2/4"
					initial="hidden"
					whileInView="visible"
					viewport={{ once: true, amount: 0.2 }}
					variants={imageVariants}
				>
					<img
						src={image.url}
						alt="Featured necklace"
						className="w-[28rem] h-[32rem] object-cover rounded-md lg:rounded-lg shadow-lg"
					/>
				</motion.div>

				{/* Text Content */}
				<motion.div
					className="w-full"
				>
					<motion.h2
						className="text-4xl font-chapman-bold mb-8"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true, amount: 0.5 }}
						variants={titleAnination}
					>
						Featuring here
					</motion.h2>
					<motion.p
						className="font-chapman-medium text-gray-700 text-lg md:text-2xl leading-relaxed"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true, amount: 0.2 }}
						variants={itemVariants}
					>
						Discover the allure of understated luxury with our gold necklace,
						featuring a single, lustrous pearl as its centerpiece. This
						exquisite piece balances sophistication and simplicity, allowing the
						radiant pearl to shine against the warm glow of gold. Perfect for
						any occasion, it's a statement of timeless elegance that adds a
						touch of refinement to any ensemble. Embrace the beauty of a classic
						design reimagined, making this necklace a treasured addition to your
						collection.
					</motion.p>
				</motion.div>
			</div>
		</section>
	);
};

export default FeaturedSection;
