import React, { useState } from "react";
import Config from "../config/Config";
import emailjs from "@emailjs/browser";

const Footer = () => {
  const countries = [...Config.Footer.countries];

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    country: "",
    comments: ""
  });

  const [errors, setErrors] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName) {
      newErrors.firstName = "First name is required";
    } else if (!/^[A-Za-z\s]{2,50}$/.test(formData.firstName)) {
      newErrors.firstName = "Please enter a valid first name (2-50 letters)";
    }

    if (!formData.lastName) {
      newErrors.lastName = "Last name is required";
    } else if (!/^[A-Za-z\s]{2,50}$/.test(formData.lastName)) {
      newErrors.lastName = "Please enter a valid last name (2-50 letters)";
    }

    if (!formData.mobile) {
      newErrors.mobile = "Mobile number is required";
    } else if (!/^[0-9+\-\s]{8,15}$/.test(formData.mobile)) {
      newErrors.mobile = "Please enter a valid phone number (8-15 digits)";
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!formData.country) {
      newErrors.country = "Please select a country";
    }

    if (!formData.comments) {
      newErrors.comments = "Comments are required";
    } else if (formData.comments.length < 10) {
      newErrors.comments = "Comments must be at least 10 characters";
    } else if (formData.comments.length > 500) {
      newErrors.comments = "Comments must not exceed 500 characters";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: ""
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);

      try {
        emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

        const templateParams = {
          first_name: formData.firstName,
          last_name: formData.lastName,
          mobile: formData.mobile,
          email: formData.email,
          country: formData.country,
          comments: formData.comments,
        };

        const result = await emailjs.send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams
        );

        if (result.text === "OK") {
          setFormData({
            firstName: "",
            lastName: "",
            mobile: "",
            email: "",
            country: "",
            comments: ""
          });
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 5000);
        }
      } catch (error) {
        console.error('Failed to send email:', error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <footer className="bg-[#10151D] py-8 px-10 md:px-4 font-chapman text-[#F3F4F6] relative">
      {showAlert && (
        <div className="fixed top-4 right-4 z-50 flex items-center bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded shadow-lg transition-opacity duration-500">
          <svg
            className="w-5 h-5 mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clipRule="evenodd"
            />
          </svg>
          <span>Thank you for the respone. We have received your comments. We'll connect with you.</span>
        </div>
      )}

      {/* About Section - Centered */}
      <div className="max-w-7xl mx-auto text-center mb-8">
        <p className="text-slate-300 max-w-2xl mx-auto text-md md:text-lg">
          We are located in Makkah, Madhinah, Jeddah, Dammam our store offers an immersive luxury shopping experience like no other. Visit Gold of the Kingdom, where luxury and craftsmanship unite to bring the finest in gold and jewelry. With us, every piece is more than just a beautiful adornment; it's a story of elegance, a touch of heritage, and a symbol of timeless beauty.
        </p>
      </div>

      {/* Shop Locations and Contact Form */}
      <div className="max-w-7xl mx-auto mt-8 pt-8 border-t border-gray-200">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Shop Locations */}
          <div>
            <h3 className="text-2xl mb-4 font-chapman-bold text-slate-300">Our Shop Locations</h3>
            <div className="grid grid-cols-1 gap-6 text-slate-300">
              {Object.entries({ ...Config.Footer.shopLocations }).map(([city, locations]) => (
                <div key={city}>
                  <h4 className="text-lg font-chapman-bold text-slate-200">{city}</h4>
                  <ul className="list-disc pl-6 space-y-2">
                    {locations.map((location, index) => (
                      <li key={index}>{location}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          {/* Contact Form */}
          <div>
            <h3 className="text-2xl mb-4 font-chapman-bold text-slate-300">Contact Us</h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400"
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">{errors.firstName}</p>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400"
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
                  )}
                </div>
              </div>
              <div>
                <input
                  type="tel"
                  placeholder="Mobile Number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400"
                />
                {errors.mobile && (
                  <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
                )}
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
              <div>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full px-4 py-2 rounded bg-gray-700 text-white"
                >
                  <option value="">Select a Country</option>
                  {countries.map((country) => (
                    <option key={country} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <p className="text-red-500 text-sm mt-1">{errors.country}</p>
                )}
              </div>
              <div>
                <textarea
                  style={{
                    resize: "none"
                  }}
                  placeholder="Comments"
                  name="comments"
                  value={formData.comments}
                  onChange={handleChange}
                  rows="3"
                  className="w-full px-4 py-2 rounded bg-gray-700 text-white placeholder-gray-400"
                />
                {errors.comments && (
                  <p className="text-red-500 text-sm mt-1">{errors.comments}</p>
                )}
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full bg-[#ddb02a] text-white py-2 px-6 rounded transition-colors duration-300 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#c49c24]'
                  }`}
              >
                {isSubmitting ? 'Sending...' : 'Submit'}
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Social Media and Payment Methods */}
      <div className="max-w-7xl mx-auto mt-8 pt-8 border-t border-gray-200">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          {/* Social Media Section */}
          <div className="flex flex-col items-center md:items-start gap-4">
            <h4 className="text-xl font-chapman-medium text-slate-300">Follow Us</h4>
            <div className="flex gap-4">
              {[...Config.Footer.socialLinks].map(({ link, platform, icon }) => (
                <a
                  href={link}
                  key={platform}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center justify-center text-gray-600 bg-white w-9 h-9 rounded-full hover:bg-[#ddb02a] hover:text-white transition-all duration-300"
                  title={platform}
                >
                  <i className={`fa-brands ${icon} fa-lg`} />
                </a>
              ))}
            </div>
          </div>

          {/* Payment Methods Section */}
          <div className="flex flex-col items-center md:items-start gap-4">
            <h4 className="text-xl font-chapman-medium text-slate-300">We Accept</h4>
            <div className="flex items-center gap-4">
              {[...Config.Footer.payments].map((payment, index) => (
                <img
                  key={index}
                  src={payment.imgsrc}
                  alt={payment.name}
                  className={payment.name === "Mada" ? "scale-110" : "scale-125"}
                  style={{
                    transform:
                      payment.name === "American Express"
                        ? "scaleX(1.25) scaleY(1.2) translateX(-5%)"
                        : (payment.name === "Mada" ? "scaleX(1.125) scaleY(1.125) translateX(-10%)" : ""),
                  }}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center mt-8 text-slate-300">
          <p>Copyright © 2025 Gold of the Kingdom</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
