import React, { useState, useEffect } from "react";
import CONFIG, { ADMIN_SERVER_URL } from "../config/Config";

const HeroSlider = () => {
	const [slides, setSlides] = useState(CONFIG.HeroSlider.Slides);
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isFading, setIsFading] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	// Fetch images from API
	useEffect(() => {
		const fetchSlides = async () => {
			try {
				const response = await fetch(`${ADMIN_SERVER_URL}/images?category=heroslider`); // Update API URL
				if (!response.ok) throw new Error("Failed to fetch slides");
				const data = await response.json();

				// Check if API returns valid data
				if (Array.isArray(data) && data.length > 0) {
					setSlides(data);
				}
			} catch (error) {
				console.error("Error fetching hero slides:", error);
				setSlides(CONFIG.HeroSlider.Slides); // Fallback to config
			}
		};

		fetchSlides();
	}, []);

	// Handle next slide
	const handleNext = () => {
		if (isFading) return;
		setIsFading(true);
		setCurrentIndex((prevIndex) =>
			prevIndex === slides.length - 1 ? 0 : prevIndex + 1
		);
	};

	// Handle previous slide
	const handlePrev = () => {
		if (isFading) return;
		setIsFading(true);
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? slides.length - 1 : prevIndex - 1
		);
	};

	// Auto slide every 5 seconds
	useEffect(() => {
		const autoSlide = setInterval(() => {
			handleNext();
		}, 5000);
		return () => clearInterval(autoSlide);
	}, [slides]);

	// Reset fading state after transition
	useEffect(() => {
		const timeout = setTimeout(() => setIsFading(false), 1000);
		return () => clearTimeout(timeout);
	}, [currentIndex]);

	// Handle scroll to apply parallax effect
	useEffect(() => {
		const handleScroll = () => {
			// Use requestAnimationFrame for smoother scroll handling
			requestAnimationFrame(() => {
				setScrollPosition(window.scrollY);
			});
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<div id="home" className="relative h-[28vh] md:h-[85vh] group bg-gray-900 overflow-hidden">
			{/* Slider Images */}
			<div className="relative w-full h-full">
				{slides.map((slide, index) => (
					<div
						key={index}
						className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${index === currentIndex ? "opacity-100 z-10" : "opacity-0 z-0"
							}`}
						style={{
							transform: `translateY(${scrollPosition * 0.4}px)`, // Adjust parallax effect speed
							willChange: "transform", // Hint to optimize performance
						}}
					>
						{/* Image */}
						<img
							src={slide.url}
							alt={`Slide ${index + 1}`}
							className="w-full h-full object-cover"
						/>

						{/* Overlay */}
						{/* <div className="absolute inset-0 bg-black bg-opacity-50"></div> */}

						{/* Text Content */}
						{/* <div className={`absolute inset-0 flex flex-col justify-center items-center text-center text-white px-4 transition-all duration-1000 ${index === currentIndex ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"}`}>
							<h1 className={`text-2xl md:text-5xl font-chapman-bold font-bold mb-4 transform transition-transform duration-1000 delay-300 ${index === currentIndex ? "translate-y-0 opacity-100" : "-translate-y-10 opacity-0"}`}>
								{slide.title}
							</h1>
							<p className={`text-md md:text-3xl font-chapman-medium mb-6 w-xl md:w-2xl max-w-2xl px-8 md:px-4 transform transition-transform duration-1000 delay-500 ${index === currentIndex ? "translate-y-0 opacity-100" : "-translate-y-10 opacity-0"}`}>
								{slide.subtitle}
							</p>
							<button className={`px-6 py-3 bg-[#FFD700] text-black font-chapman font-semibold rounded-full scale-75 md:scale-110 transform transition-all duration-1000 delay-700 ${index === currentIndex ? "translate-y-0 opacity-100" : "-translate-y-10 opacity-0"}`}>
								{slide.buttonText}
							</button>
						</div> */}
					</div>
				))}
			</div>

			{/* Navigation Buttons */}
			<button
				onClick={handlePrev}
				className="absolute top-1/2 -translate-y-1/2 left-0 md:left-3.5 lg:group-hover:left-5 h-12 w-12 rounded-full flex items-center justify-center bg-transparent lg:hover:shadow-arrow text-white hover:text-[#FFD700] transition-all duration-300 z-20"
				disabled={isFading}
			>
				<svg
					className="w-10 h-10"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeWidth={3}
						d="M15 19l-7-7 7-7"
					/>
				</svg>
			</button>
			<button
				onClick={handleNext}
				className="absolute top-1/2 -translate-y-1/2 right-0 md:right-3.5 lg:group-hover:right-5 h-12 w-12 rounded-full flex items-center justify-center bg-transparent lg:hover:shadow-arrow text-white hover:text-[#FFD700] transition-all duration-300 z-20"
				disabled={isFading}
			>
				<svg
					className="w-10 h-10"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
				>
					<path
						strokeWidth={3}
						d="M9 5l7 7-7 7"
					/>
				</svg>
			</button>

			{/* Dots for Indicators */}
			<div className="absolute bottom-6 w-full flex justify-center space-x-3 z-20">
				{slides.map((_, index) => (
					<div
						key={index}
						className={`h-1 ${index === currentIndex ? "w-8 bg-[#FFD700]" : "w-4 bg-white"
							} transition-all duration-1000 transform ${index === currentIndex ? "scale-y-125" : "scale-100"
							}`}
					></div>
				))}
			</div>
		</div>
	);
};

export default HeroSlider;