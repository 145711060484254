import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Config from "../config/Config";

const ProductCategories = () => {
    const navigate = useNavigate();

    const itemVariants = {
        hidden: {
            opacity: 0,
            scale: 0.8,
            y: 50,
        },
        visible: {
            opacity: 1,
            scale: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: [0.42, 0, 0.58, 1],
            },
        },
    };

    const categories = [...Config.ProductCategories];

    return (
        <div className="flex flex-wrap justify-center gap-6 md:gap-8 mb-8 lg:px-4">
            {categories.map((category) => (
                <motion.div
                    key={category.id}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.4 }}
                    variants={itemVariants}
                    className="group relative"
                    onClick={() => {
                        navigate(`/products/${category.id}`);
                    }}
                >
                    <motion.div
                        whileHover={{
                            scale: 1.05,
                            rotate: [0, -5, 5, 0],
                            transition: {
                                duration: 0.4,
                                type: "spring",
                                stiffness: 300
                            }
                        }}
                        whileTap={{
                            scale: 0.95,
                            rotate: 0,
                            transition: {
                                duration: 0.2
                            }
                        }}
                        className={`
                            cursor-pointer 
                            relative 
                            flex 
                            flex-col 
                            items-center 
                            transition-all 
                            duration-300 
                            text-gray-500 
                            hover:text-black
                            p-4
                            rounded-lg
                            hover:bg-white/5
                            backdrop-blur-sm
                        `}
                    >
                        <div
                            className={`
                                w-28 
                                h-28 
                                lg:w-36 
                                lg:h-36 
                                rounded-full 
                                overflow-hidden 
                                shadow-lg
                                relative 
                                transition-all 
                                duration-300
                                group-hover:scale-110
                                group-hover:shadow-2xl
                                group-hover:shadow-amber-500/20
                                ring-2
                                ring-white/10
                                ring-offset-2
                                ring-offset-transparent
                                group-hover:ring-[#ddb02a]
                            `}
                        >
                            <motion.img
                                src={category.image}
                                alt={category.name}
                                className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                                transition={{
                                    duration: 0.3,
                                    type: "tween"
                                }}
                            />
                        </div>

                        <motion.h4
                            className={`
                                text-xl 
                                font-chapman-medium 
                                text-center 
                                mt-4 
                                mb-2 
                                transition-all 
                                duration-300 
                                ease-in-out
                                group-hover:scale-110
                                group-hover:font-semibold
                                drop-shadow-md
                                group-hover:drop-shadow-lg
                            `}
                        >
                            {category.name}
                        </motion.h4>

                        {/* Enhanced animated underline with glow effect */}
                        <motion.div
                            className={`
                                absolute 
                                bottom-0 
                                left-0 
                                w-full 
                                h-1 
                                bg-gradient-to-r 
                                from-amber-500 
                                to-amber-700
                                opacity-0
                                group-hover:opacity-50
                                shadow-lg
                                shadow-amber-500/50
                                rounded-full
                            `}
                            initial={{ width: 0 }}
                            animate={{
                                transition: {
                                    duration: 0.3,
                                    ease: "easeInOut"
                                }
                            }}
                        />
                    </motion.div>
                </motion.div>
            ))}
        </div>
    );
};

export default ProductCategories;