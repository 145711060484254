import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const FeaturedSlider = ({ images, inView = true }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images]);

    const featuredAnimation = {
        hidden: {
            x: -100,
            opacity: 0,
            rotateY: -45
        },
        visible: {
            x: 0,
            opacity: 1,
            rotateY: 0,
            transition: {
                duration: 1.5,
                ease: "easeOut",
                type: "spring",
                stiffness: 100
            }
        }
    };

    return (
        <motion.div
            variants={featuredAnimation}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            className="relative perspective-1000"
        >
            <div className="h-[40vh] md:h-[624px] overflow-hidden rounded-sm shadow-md relative">
                {images.map((image, index) => (
                    <motion.img
                        key={index}
                        src={image.url}
                        alt={`Featured ${index + 1}`}
                        className="w-full h-full object-cover absolute"
                        style={{ x: `${(index - currentSlide) * 100}%` }}
                        initial={{ scale: 1.2, rotate: 2 }}
                        animate={{
                            scale: 1,
                            rotate: 0,
                            x: `${(index - currentSlide) * 100}%`,
                            transition: { duration: 0.75 }
                        }}
                    />
                ))}
                <motion.div
                    className="absolute bottom-2 inset-x-0 flex justify-center space-x-2"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5 }}
                >
                    {images.map((_, index) => (
                        <motion.span
                            key={index}
                            className={`w-2 h-2 rounded-full cursor-pointer ${index === currentSlide ? "bg-white" : "bg-gray-400"}`}
                            onClick={() => setCurrentSlide(index)}
                            whileHover={{ scale: 1.3, backgroundColor: "#fff" }}
                            whileTap={{ scale: 0.8 }}
                        />
                    ))}
                </motion.div>
            </div>
        </motion.div>
    );
};

export default FeaturedSlider;