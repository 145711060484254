import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Admin from "./components/admin/Admin";
import ProtectedRoute from "./components/admin/ProtectedRoute";
import Login from "./components/admin/Login";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/*" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route element={<ProtectedRoute />}>
                <Route path="/admin" element={<Admin />} />
            </Route>
        </Routes>
    </BrowserRouter>
);
