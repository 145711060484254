const Config = {
    Navbar: {
        logoimg: "/images/logo.png"
    },
    HeroSlider: {
        Slides: [
            {
                url: "/images/heroslider/GOK_HeroBanner-1.jpg",
            },
            {
                url: "/images/heroslider/GOK_HeroBanner-2.jpg",
            },
            {
                url: "/images/heroslider/GOK_HeroBanner-3.jpg",
            },
            {
                url: "/images/heroslider/GOK_HeroBanner-4.jpg",
            },
            {
                url: "/images/heroslider/GOK_HeroBanner-5.jpg",
            },
            {
                url: "/images/heroslider/GOK_HeroBanner-6.jpg",
            },
            {
                url: "/images/heroslider/GOK_HeroBanner-7.jpg",
            }
        ]
    },
    NewCollections: {
        FeaturedSlider: [
            {
                url: "/images/newcollections/big/004.jpg"
            },
            {
                url: "/images/newcollections/big/005.jpg"
            },
            {
                url: "/images/newcollections/big/006.jpg"
            }
        ],
        MediumSlider: [
            {
                url: "/images/newcollections/medium/007.jpg"
            },
            {
                url: "/images/newcollections/medium/008.jpg"
            },
            {
                url: "/images/newcollections/medium/009.jpg"
            }
        ],
        SmallSliders: [
            [
                {
                    url: "/images/newcollections/small/010.jpg"
                },
                {
                    url: "/images/newcollections/small/011.jpg"
                },
                {
                    url: "/images/newcollections/small/012.jpg"
                }
            ],
            [
                {
                    url: "/images/newcollections/small/012.jpg"
                },
                {
                    url: "/images/newcollections/small/010.jpg"
                },
                {
                    url: "/images/newcollections/small/011.jpg"
                }
            ]
        ]
    },
    FeaturedSection: {
        image: {
            url: "/images/featured/013.jpg"
        }
    },
    ProductCategories: [
        {
            id: "new_arrivals", name: "New Arrivals", image: "./images/category/New_Arrivals.png", data: [
                { url: '/images/category/new_arrivals/New_Arrivals1.png', text: "Product 1 in New Arrival" },
                { url: '/images/category/new_arrivals/New_Arrivals2.png', text: "Product 2 in New Arrival" },
                { url: '/images/category/new_arrivals/New_Arrivals3.png', text: "Product 3 in New Arrival" },
                { url: '/images/category/new_arrivals/New_Arrivals4.png', text: "Product 4 in New Arrival" },
                { url: '/images/category/new_arrivals/New_Arrivals5.png', text: "Product 5 in New Arrival" },
                { url: '/images/category/new_arrivals/New_Arrivals6.png', text: "Product 6 in New Arrival" }
            ]
        },
        {
            id: "rings", name: "Rings", image: "./images/category/Rings.png", data: [
                { url: '/images/category/rings/Rings1.png', text: "Product 1 in Rings" },
                { url: '/images/category/rings/Rings2.png', text: "Product 2 in Rings" },
                { url: '/images/category/rings/Rings3.png', text: "Product 3 in Rings" },
                { url: '/images/category/rings/Rings4.png', text: "Product 4 in Rings" },
                { url: '/images/category/rings/Rings5.png', text: "Product 5 in Rings" }
            ]
        },
        {
            id: "necklaces", name: "Necklaces", image: "./images/category/Necklaces.png", data: [
                { url: '/images/category/necklaces/Necklaces1.png', text: "Product 1 in Necklaces" },
                { url: '/images/category/necklaces/Necklaces2.png', text: "Product 2 in Necklaces" },
                { url: '/images/category/necklaces/Necklaces3.png', text: "Product 3 in Necklaces" },
                { url: '/images/category/necklaces/Necklaces4.png', text: "Product 4 in Necklaces" },
                { url: '/images/category/necklaces/Necklaces5.png', text: "Product 5 in Necklaces" },
                { url: '/images/category/necklaces/Necklaces6.png', text: "Product 6 in Necklaces" }
            ]
        },
        {
            id: "bracelets", name: "Bracelets", image: "./images/category/Bracelets.png", data: [
                { url: '/images/category/bracelets/Bracelets1.png', text: "Product 1 in Bracelets" },
                { url: '/images/category/bracelets/Bracelets2.png', text: "Product 2 in Bracelets" },
                { url: '/images/category/bracelets/Bracelets3.png', text: "Product 3 in Bracelets" },
                { url: '/images/category/bracelets/Bracelets4.png', text: "Product 4 in Bracelets" },
            ]
        },
        {
            id: "earrings", name: "Earrings", image: "./images/category/Earrings.png", data: [
                { url: '/images/category/earrings/Earrings1.png', text: "Product 1 in Earrings" },
                { url: '/images/category/earrings/Earrings2.png', text: "Product 2 in Earrings" },
                { url: '/images/category/earrings/Earrings3.png', text: "Product 3 in Earrings" },
                { url: '/images/category/earrings/Earrings4.png', text: "Product 4 in Earrings" }
            ]
        },
        {
            id: "bangles", name: "Bangles", image: "./images/category/Bangles.png", data: [
                { url: '/images/category/bangles/Bangles1.png', text: "Product 1 in Bangles" },
                { url: '/images/category/bangles/Bangles2.png', text: "Product 2 in Bangles" },
                { url: '/images/category/bangles/Bangles3.png', text: "Product 3 in Bangles" },
                { url: '/images/category/bangles/Bangles4.png', text: "Product 4 in Bangles" },
                { url: '/images/category/bangles/Bangles5.png', text: "Product 5 in Bangles" },
                { url: '/images/category/bangles/Bangles6.png', text: "Product 6 in Bangles" }
            ]
        },
        {
            id: "pendants", name: "Pendants", image: "./images/category/Pendants.png", data: [
                { url: '/images/category/pendants/Pendants1.png', text: "Product 1 in Pendants" },
                { url: '/images/category/pendants/Pendants2.png', text: "Product 2 in Pendants" },
                { url: '/images/category/pendants/Pendants3.png', text: "Product 3 in Pendants" },
                { url: '/images/category/pendants/Pendants4.png', text: "Product 4 in Pendants" },
                { url: '/images/category/pendants/Pendants5.png', text: "Product 5 in Pendants" },
                { url: '/images/category/pendants/Pendants6.png', text: "Product 6 in Pendants" }
            ]
        },
        {
            id: "anklets", name: "Anklets", image: "./images/category/Anklets.png", data: [
                { url: '/images/category/anklets/Anklets1.png', text: "Product 1 in Anklets" }
            ]
        },
        {
            id: "chains", name: "Chains", image: "./images/category/Chains.png", data: [
                { url: '/images/category/chains/Chains1.png', text: "Product 1 in Chains" }
            ]
        },
        {
            id: "nose_pins", name: "Nose Pins", image: "./images/category/Nose_Pins.png", data: [
                { url: '/images/category/nose_pins/Nose_Pins1.png', text: "Product 1 in Nose Pins" }
            ]
        },
        {
            id: "coins_bars", name: "Coins & Bars", image: "./images/category/Coins_Bars.png", data: [
                { url: '/images/category/coins_bars/Coins_Bars1.png', text: "Product 1 in Coins & Bars" },
                { url: '/images/category/coins_bars/Coins_Bars2.png', text: "Product 2 in Coins & Bars" }
            ]
        },
        {
            id: "silver_coins", name: "Silver Coins", image: "./images/category/Silver_Coins.png", data: [
                { url: '/images/category/silver_coins/Silver_Coins1.png', text: "Product 1 in Silver Coins" }
            ]
        }
    ],
    Footer: {
        shopLocations: {
            Makkah: [
                "Al Haram, Clock Tower, 3rd Floor.",
                "Al Hijaz Mall, Makkah - Jeddah Exp."
            ],
            Jeddah: [
                "King Abdul Aziz International Airport, T 1.",
                "Balad, Qabeel St. Al Sharbatli Building."
            ],
            Madinah: ["As Safiyyah Museum and Park, King Faisal Road."],
            Dammam: ["Domestic Airport Departure, Dammam."]
        },
        countries: [
            "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
            "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
            "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
            "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
            "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador",
            "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France",
            "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau",
            "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland",
            "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Kuwait",
            "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg",
            "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico",
            "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia", "Nauru",
            "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman",
            "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal",
            "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Saudi Arabia",
            "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
            "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan",
            "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
            "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela",
            "Vietnam", "Yemen", "Zambia", "Zimbabwe"
        ],
        socialLinks: [
            { link: "https://www.facebook.com/goldofkingdom/", platform: "Facebook", icon: "fa-facebook" },
            { link: "https://www.instagram.com/goldofthekingdom", platform: "Instagram", icon: "fa-instagram" },
            { link: "https://wa.me/966537561652", platform: "WhatsApp", icon: "fa-whatsapp" },
            { link: "https://www.snapchat.com/add/goldkingdom7899?share_id=n8qdGSJ1TZuXErz6IQz6_A&locale=ar_EG", platform: "Snapchat", icon: "fa-snapchat" },
            { link: "https://www.tiktok.com/@goldofthekingd?_t=ZS-8tCcJYJxWFN&_r=1", platform: "Tik Tok", icon: "fa-tiktok" }
        ],
        payments: [
            { name: "Apple Pay", imgsrc: "/images/payment/applepay.svg" },
            { name: "Master Card", imgsrc: "/images/payment/mastercard.svg" },
            { name: "Visa", imgsrc: "/images/payment/visacard.svg" },
            { name: "American Express", imgsrc: "/images/payment/americanexpress.svg" },
            { name: "Mada", imgsrc: "/images/payment/mada.svg" }
        ]
    }
}

export default Config;

export const ADMIN_SERVER_URL = "https://client00001-cms.onrender.com"