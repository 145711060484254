// About Section Component
const AboutSection = () => {
	return (
		<section id="about" className="container mx-auto py-12 px-10 md:px-12">
			<h2 className="text-4xl font-chapman-bold text-center mb-8">About Us</h2>
			<p
				className="font-chapman-medium text-center text-gray-700 text-xl md:text-2xl max-w-7xl mx-auto"
			>
				At Gold of the Kingdom, we pride ourselves on being a premier destination for luxury jewelry, blending heritage and innovation in every piece. Established in the heart of Jeddah, Saudi Arabia, our brand embodies the timeless elegance of gold, the brilliance of diamonds, and the vibrant beauty of precious stones. From classic designs rooted in tradition to modern, avant-garde creations, our collections cater to a diverse clientele. Our commitment to exceptional craftsmanship and unparalleled customer service ensures that every visit is a memorable experience. At Gold of the Kingdom, we don’t just sell jewelry—we craft stories of elegance and enduring beauty.
			</p>
		</section>
	);
};

export default AboutSection;
