import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

const SmallSlider = ({ images, rowIndex, inView = true }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % images.length);
        }, rowIndex === 0 ? 5000 : 4500);

        return () => clearInterval(interval);
    }, [images, rowIndex]);

    const smallSliderAnimation = {
        hidden: {
            x: rowIndex === 0 ? -50 : 50,
            opacity: 0,
            rotateZ: rowIndex === 0 ? -10 : 10
        },
        visible: {
            x: 0,
            opacity: 1,
            rotateZ: 0,
            transition: {
                duration: 1,
                type: "spring",
                bounce: 0.4
            }
        }
    };

    return (
        <motion.div
            variants={smallSliderAnimation}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            custom={rowIndex}
            className="relative h-[25vh] lg:h-[300px]  overflow-hidden rounded-sm shadow-md"
        >
            {images.map((image, index) => (
                <motion.img
                    key={index}
                    src={image.url}
                    alt={`Small Slider ${rowIndex + 1} - ${index + 1}`}
                    className={`w-full h-full object-cover absolute transition-opacity duration-[1200ms] ${index === currentSlide ? "opacity-100" : "opacity-0"}`}
                    initial={{ scale: 1.1, rotate: rowIndex === 0 ? -5 : 5 }}
                    animate={{
                        scale: 1,
                        rotate: 0,
                        transition: { duration: 0.8 }
                    }}
                />
            ))}
            <motion.div
                className="absolute bottom-2 inset-x-0 flex justify-center space-x-2"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
            >
                {images.map((_, index) => (
                    <motion.span
                        key={index}
                        className={`w-1.5 h-1.5 rounded-full cursor-pointer ${index === currentSlide ? "bg-white" : "bg-gray-400"}`}
                        onClick={() => setCurrentSlide(index)}
                        whileHover={{ scale: 1.3 }}
                        whileTap={{ scale: 0.8 }}
                    />
                ))}
            </motion.div>
        </motion.div>
    );
};

export default SmallSlider;