import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import FeaturedSlider from "./newcollections/FeaturedSlider";
import MediumSlider from "./newcollections/MediumSlider";
import SmallSlider from "./newcollections/SmallSlider";
import CONFIG, { ADMIN_SERVER_URL } from "../config/Config";

const NewCollections = () => {
	// Data for sliders
	let featuredSlider = [...CONFIG.NewCollections.FeaturedSlider];
	let mediumSlider = [...CONFIG.NewCollections.MediumSlider];
	let smallSliders = [[...CONFIG.NewCollections.SmallSliders[0]], [...CONFIG.NewCollections.SmallSliders[1]]];

	// Fetch images from API
	useEffect(() => {
		const fetchSlides = async (category, outputArray) => {
			try {
				const response = await fetch(`${ADMIN_SERVER_URL}/images?category=${category}`); // Update API URL
				if (!response.ok) throw new Error("Failed to fetch slides");
				const data = await response.json();

				// Check if API returns valid data
				if (Array.isArray(data) && data.length > 0) {
					outputArray = data;
				}
			} catch (error) {
				console.error("Error fetching hero slides:", error);
			}
		};

		fetchSlides("newcollections/big", featuredSlider);
		fetchSlides("newcollections/medium", mediumSlider);
		fetchSlides("newcollections/small1", smallSliders[0]);
		fetchSlides("newcollections/small2", smallSliders[1]);
	}, []);

	// Refs for scroll animations
	const titleRef = React.useRef(null);
	const featuredRef = React.useRef(null);
	const mediumRef = React.useRef(null);
	const smallRef1 = React.useRef(null);
	const smallRef2 = React.useRef(null);

	const titleInView = useInView(titleRef, { once: true, margin: "-100px" });
	const featuredInView = useInView(featuredRef, { once: true, margin: "-100px" });
	const mediumInView = useInView(mediumRef, { once: true, margin: "-100px" });
	const small1InView = useInView(smallRef1, { once: true, margin: "-100px" });
	const small2InView = useInView(smallRef2, { once: true, margin: "-100px" });

	// Title animation variants
	const titleAnimation = {
		hidden: {
			opacity: 0,
			scale: 1.2,
			filter: "blur(10px)"
		},
		visible: {
			opacity: 1,
			scale: 1,
			filter: "blur(0px)",
			transition: {
				duration: 1,
				ease: [0.6, 0.01, 0.32, 1]
			}
		}
	};

	return (
		<section
			id="new_collections"
			className="container mx-auto py-12 px-6 md:px-12 lg:px-18 overflow-hidden"
		>
			<motion.h2
				ref={titleRef}
				variants={titleAnimation}
				initial="hidden"
				animate={titleInView ? "visible" : "hidden"}
				className="text-4xl font-chapman-bold text-center mb-8"
			>
				New Collections
			</motion.h2>
			<div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
				{/* Featured Slider */}
				<div ref={featuredRef} className="lg:col-span-2">
					<FeaturedSlider
						images={featuredSlider}
						inView={featuredInView}
					/>
				</div>

				{/* Right Section */}
				<div className="lg:col-span-3 flex flex-col gap-6">
					{/* Medium Slider */}
					<div ref={mediumRef}>
						<MediumSlider
							images={mediumSlider}
							inView={mediumInView}
						/>
					</div>

					{/* Small Sliders */}
					<div className="grid grid-cols-2 gap-6">
						{smallSliders.map((slider, rowIndex) => (
							<div
								key={rowIndex}
								ref={rowIndex === 0 ? smallRef1 : smallRef2}
							>
								<SmallSlider
									images={slider}
									rowIndex={rowIndex}
									inView={rowIndex === 0 ? small1InView : small2InView}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default NewCollections;